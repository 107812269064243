(function(){

	var module = 'sidebar';
	var $body = $('html, body');

	setCategory();

	function setCategory(){
		var $item_container = $('.has-children').find('.is-hidden');
		var $items_list = $item_container.find('li');
		var $item = $items_list.children('a[data-category]');

		$item.on('click', $items_list, function(event){
			var category = $(this).attr('data-category'); 
			window.sessionStorage.setItem('category', category);
		});
	};

})();